<template>
<div>
  Thank you for subscribing to our service. You will receive an email with the details of your subscription.
</div>
</template>
<script>
import Userauth from "../auth/js/index";
import UserService from '../../services/userController'

export default {
  async mounted() {

          try {
              let response = await UserService.isSubscribed({
                id: 'stripe',
                password: 'stripe'
              });  

              if(response.result){
                    if(response.message.isactive){
                      Userauth.localLogin(response.message);
                      this.$toasted.show('Successfully Logged In', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
                    
                          this.$router.replace("/app/dashboard");
                      
                      this.auth = setInterval(() => {
                        this.Auth();
                      }, 120000)
                    }
                    else{
                      this.$router.replace("/auth/verification");
                      // this.$toasted.show('Your Account is not Activated. Please verify your account.', {theme: 'outline',position: "bottom-center", type: 'error', duration: 4000});
                    }
                }
                else{
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                    // Userauth.Logout();
                }

          } catch (e) {
            console.error("Error fetching subscription URL:", e);
          }
  }

}

</script>
